.c-ModalRow {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &--centered-horizontally {
    justify-content: center;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--bound-to-bottom {
    margin-top: auto;
  }
}

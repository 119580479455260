@import '@clientCommon/style-variables-colors.scss';

.c-TableLayoutTitle {
  margin-bottom: 28px;

  > .title {
    font-size: 24px;
    color: $main-blue;
    font-weight: 300;
  }
}

@import '@clientCommon/style-variables-colors.scss';

.c-ArrowBack,
.c-ArrowBackDisabled {
  color: $darker-grey;
  margin-right: 10px;
}

.c-ArrowBack {
  cursor: pointer;
}

.c-ArrowBackDisabled {
  pointer-events: none;
}

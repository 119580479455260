@import '@clientCommon/style-variables-colors.scss';

$header-height: 51px;

.v-Header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  height: $header-height;
  background-color: #f9fafb;
  padding: 0 12px;
  border-bottom: solid 1px #e2e7e9;
  justify-content: space-between;

  .left-section {
    padding: 14px 0;

    .medifriendrx-logo {
      font-size: 19px;
      line-height: 12px;
      color: #59a3cf;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }
  }

  > .right-section {
    display: flex;
    flex-direction: row;

    > .log-out-btn {
      font-size: 19px;
      padding: 7px 15px;
      color: $white;
      background: $dark-red;
      border: none;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
    }
  }
}

@import '@clientCommon/style-variables-colors.scss';

.r-PrescriptionFillsManualEntryView {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  padding: 24px;

  > .header-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 28px;

    > .title {
      font-size: 24px;
      font-weight: 300;
      color: $main-blue;
    }

    > .kiosk-select {
      display: flex;
      flex: 0 1 auto;
      width: 300px;

      &.warning {
        border: 2px solid $dark-red;
        border-radius: 5px;
        padding: 14px;
      }
    }
  }

  .identifiers-grouping {
    &.warning {
      border: 2px solid $dark-red;
      border-radius: 5px;
      padding: 18px;
      padding-right: 0px;
      margin-right: 79px;
    }
  }

  .row {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  }

  > .data-input-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .entity-container {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin-bottom: 15px;

      > .header {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        > .title-container {
          display: flex;
          flex: 0 1 auto;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 5px;

          > .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          > .title {
            font-size: 20px;
          }
        }
      }

      > .inputs {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .input-with-margin {
          margin-right: 50px;
        }
      }
    }
  }

  > .footer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;

    > .buttons-container {
      display: flex;
      margin-bottom: 10px;

      > .btn {
        height: 40px;
        width: 140px;
        font-size: 18px;
        color: $white;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        outline: none;
        border: none;
        cursor: pointer;
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      > .btn-blue {
        background-color: $main-blue;
      }

      > .btn-red {
        background-color: $dark-red;
      }

      > .btn[disabled] {
        background-color: $light-grey;
      }
    }

    > .result-message {
      font-size: 18px;
    }

    > .success {
      color: $green;
    }

    > .error {
      color: $dark-red;
    }
  }
}

@import '@clientCommon/style-variables-colors.scss';

.c-ClearSectionButton {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 50px;

  &:hover {
    text-decoration: underline;
  }

  > .text {
    font-size: 18px;
    color: $text-mid-grey;
    margin-right: 8px;
  }

  > .icon {
    width: 12px;
    height: 12px;
  }
}

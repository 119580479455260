@import '../../../styles/colors.scss';

h4.h4 {
  font-size: 20px;
  line-height: 24px;
  color: $main-blue;
  font-weight: 400;
  margin: 0px;

  &.neutral {
    color: $text-dark-grey;
  }
}

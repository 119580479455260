.c-LotPrescrptionFills {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;

  > .search {
    margin-bottom: 10px;
  }

  > .no-prescription-fills-text {
    font-size: 16px;
  }
}

@import '@clientCommon/style-variables-colors.scss';

.v-Navbar {
  display: flex;
  flex: 0 0 216px;
  min-width: 216px;
  position: relative;
  flex-direction: column;
  background-color: $white;

  .route-container {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    cursor: pointer;

    > .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    > .route {
      font-size: 16px;
      color: $black;
    }

    > .route-active {
      color: $main-blue;
    }
  }
}

@import '@clientCommon/style-variables-colors.scss';

.c-TableLayoutTableHeaderNewEntryButton {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  width: max-content;
  color: $white;
  background: $main-blue;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;

  > .plus-sign {
    height: 100%;
    font-size: 18px;
  }

  > .new-entry-btn {
    font-size: 16px;
    color: $white;
    background: $main-blue;
    outline: none;
    border: none;
    cursor: pointer;
  }
}

@import '../../../../../../styles/colors.scss';

.c-HeaderView {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 24px;
  border-bottom: solid 1px $border-grey;

  .filter-container {
    margin-top: 28px;
  }
}

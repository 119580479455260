@import '../../../styles/colors.scss';

h3.h3 {
  font-size: 24px;
  line-height: 28px;
  color: $main-blue;
  font-weight: 400;
  margin: 0px;

  &.neutral {
    color: $text-dark-grey;
  }
}

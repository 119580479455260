@import '@clientCommon/style-variables-colors.scss';

.c-ModalEditingArea {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 50%;
  padding: 10px;
  overflow-y: auto;
}

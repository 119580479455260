@import '@clientCommon/style-variables-colors.scss';

.c-AppFrameView {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  max-width: 1530px;
  position: relative;

  > .content {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;

    > .navbar {
      display: flex;
      flex: 0 1 auto;
      overflow: hidden;
    }

    > .primary-content {
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;
    }
  }
}

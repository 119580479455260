@import '../../../../styles/colors.scss';

.c-ListView {
  display: flex;
  flex-direction: column;
  flex: 0 0 436px;
  border-left: solid 1px $border-grey;
  border-right: solid 1px $border-grey;

  .group {
    .group-title-container {
      display: flex;
      flex: 0 0 auto;
      margin-bottom: 24px;

      .group-title {
        flex: 0 0 auto;
        text-transform: uppercase;
        color: #B6BEC2;
        font-size: 15px;
        line-height: 20px;
        margin-right: 24px;
      }

      .horizontal-rule-container {
        flex: 1 0 auto;

        hr {
          display: block;
          height: 1px;
          border: 0;
          border-top: 1px solid #E2E7E9;
          padding: 0;
          margin-top: 10px;
        }
      }
    }
  }

  .items-pane {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 24px;
    overflow-y: auto;
    position: relative;
  }
}

@import '../../../../../../styles/colors.scss';

.c-ListItemView {
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 24px;

  &.active {
    .list-item-label-container {
      .list-item-label {
        color: $main-blue;
      }
    }

    .list-item-container {
      border: solid 2px $main-blue;
    }
  }

  .list-item-label-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 30px;
    max-width: 75px;

    .list-item-label {
      font-size: 14px;
      color: $text-grey;
      min-width: 60px;
      margin-right: 10px;
    }
  }

  .list-item-container {
    display: flex;
    flex: 1 1 auto;
    padding: 24px;
    background-color: $white;
    border-radius: 4px;
    border: solid 2px $border-grey;
    cursor: pointer;

    .content-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .title-container {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;

        .title {
          flex: 1 1 auto;
        }

        .title-right {
          display: flex;
          flex: 0 0 auto;
        }
      }

      .body-container {
        flex: 0 0 auto;
        margin-top: 12px;
      }
    }

    .icons-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 24px;
      margin-right: 12px;

      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}

@import '@clientCommon/style-variables-colors.scss';

.c-ArrowForward,
.c-ArrowForwardDisabled {
  color: $darker-grey;
  margin-left: 10px;
}

.c-ArrowForward {
  cursor: pointer;
}

.c-ArrowForwardDisabled {
  pointer-events: none;
}

@import "./@clientCommon/library//styles/colors.scss";

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0 !important;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: hidden;
  height: 100%;
  background-color: $spec-white;
}

a {
  color: inherit;
  text-decoration: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: hidden;
}

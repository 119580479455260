.c-KioskView {
  display: flex;
  flex: 1 1 100%;

  .c-ListItemView {
    .title-right {
      .kiosk-status {
        display: flex;

        .kiosk-online {
          display: flex;
          flex-direction: row;

          img {
            height: 25px;
            width: 25px;
          }
        }

        .kiosk-temperature {
          display: flex;
          flex-direction: row;

          img {
            height: 25px;
            width: 25px;
          }
        }
      }
    }

    .kiosk-data {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .healthcare-site-name {
        font-weight: bolder;
        margin-bottom: 8px;
      }
    }
  }
}

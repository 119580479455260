.c-TableLayoutTableHeader {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;

  > .left-area,
  > .right-area {
    display: flex;
    flex: 1 1 auto;
    width: 50%;
    height: 100%;
  }

  > .left-area {
    justify-content: flex-start;
  }

  > .right-area {
    justify-content: flex-end;

    > .search-input-container {
      width: 60%;
      max-width: 400px;
    }
  }
}
